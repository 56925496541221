html {
  font-size: 10px;
}

body {
  background: url('./resources/background.png') top center no-repeat;
  background-color: #33353c;
  font-family: "Roboto Slab", sans-serif;
  line-height: 1.42857143;
  font-size: 1.4rem;
  color: #777982;
  margin: 0;
}

#root {
  margin-left: auto;
  margin-right: auto;
  padding-left: 1.5rem;

  padding-right: 1.5rem;
}

* {
  box-sizing: border-box;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 100;
  line-height: 1.1;
  color: #fff;
}

@media (min-width: 992px) {
  #root {
    width: 970px;
  }
}

@media (min-width: 1200px) {
  #root {
    width: 1170px;
  }
}
